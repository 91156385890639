<template>
  <card-container backButton closeButton>
    <div class="flex flex-col h-full p-8 space-y-6">
      <div class="flex-grow space-y-8 overflow-scroll">
        <div class="mt-10 text-center text-8xl">⚠️</div>
        <div class="text-2xl text-center">
          Er du helt sikker på at du vil slette {{ location.title }}?
        </div>
        <div class="space-y-6">
          <div>
            Husk at andre kan ha lagret plassen som favoritt så det er synd hvis den blir borte.
          </div>
          <div>
            Hvis du likevel vil slette, skriv <strong>Helt sikker</strong> i tekstfeltet under og
            klikk <strong>Slett</strong>.
          </div>
        </div>
      </div>

      <div class="space-y-4 text-center">
        <input
          v-model="confirmationInput"
          class="p-2 text-xl text-center rounded-lg w-52 focus:outline-none focus:ring focus:border-blue-300"
          type="text"
          placeholder="Helt sikker"
        />
        <button
          class="flex-1 px-10 py-3 text-xl border-2 border-red-600 rounded-full disabled:text-gray-200"
          :disabled="confirmationInput !== 'Helt sikker'"
          @click="deleteLocation"
        >
          Slett
        </button>
      </div>
    </div>
  </card-container>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import CardContainer from '../components/CardContainer.vue'

export default {
  components: { CardContainer },

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const confirmationInput = ref('')
    const location = store.getters.locationById(route.params.id)

    const deleteLocation = () => {
      store.dispatch('deleteLocation', location).then(() => {
        router.push({
          name: 'home',
          params: { transition: 'zoom-in' },
        })
        console.log('Location deleted')
      })
    }

    return {
      location,
      confirmationInput,
      deleteLocation,
    }
  },
}
</script>
